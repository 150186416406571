import * as dateFns from 'date-fns'

import { MapDataNode, MapDataRoot, MapSubtypeId, PerfData } from '../../../app/maps/types'
import { getMapsRefreshInterval } from '../../../app/maps/utils'
import { getSanitizedTicker } from '../../../app/shared/ticker-sanitizer'
import { parseInitData } from '../../../app/shared/utils'
import * as storage from '../../services/local-storage'
import { formatNumber, getUuid } from '../../util'
import { columnMap } from './constants/columns'
import {
  CASH_TRANSACTIONS,
  EMPTY_FORM_ENTRY,
  PORTFOLIO_CASH_SYMBOL,
  PORTFOLIO_DATE_FORMAT_DATA,
  PORTFOLIO_DATE_FORMAT_VIEW,
  PORTFOLIO_REFRESH_INTERVAL_ELITE,
  PORTFOLIO_REFRESH_INTERVAL_FREE,
  STOCK_TRANSACTIONS,
} from './constants/general'
import * as api from './services/api'
import {
  ColumnId,
  FormErrors,
  PortfolioEntryBase,
  PortfolioEntryTransaction,
  PortfolioFormEntry,
  PortfolioFormGroup,
  PortfolioGroup,
  PortfolioInitData,
  PortfolioMapEntry,
  PortfolioMapSizeKey,
  PortfolioQuery,
  PortfolioSummary,
  PortfolioSymbolType,
  PortfolioTableView,
  PortfolioTrade,
  PortfolioTransaction,
  PortfolioTransactionStake,
  PortfolioView,
} from './types'

export enum PortfolioDataKey {
  Cash = 'cash',
  LongGainers = 'longGainers',
  LongLosers = 'longLosers',
  ShortGainers = 'shortGainers',
  ShortLosers = 'shortLosers',
}

const industryLabelKey = 'description'

const groupPrefix = {
  long: 'Long',
  short: 'Short',
}

const groupLabel = {
  gainers: 'Gainers',
  losers: 'Losers',
}

export const LOCAL_STORAGE_KEY = 'portfolio-map'

type PortfolioMapData = { [key in PortfolioDataKey]: MapDataNode[] }

export interface PortfolioSavedSettings {
  tableView: PortfolioTableView
  showMap: boolean
  showIndustries: boolean
  subtype: MapSubtypeId
  tableOrder: string
}

export const DEFAULT_SETTINGS: PortfolioSavedSettings = {
  tableView: PortfolioTableView.Ticker,
  showMap: true,
  showIndustries: true,
  subtype: MapSubtypeId.PortfolioGainPct,
  tableOrder: columnMap[ColumnId.Order].urlSort,
}

/**
 * Map param in query/local storage is string but we need a boolean
 */
export function parseBoolFromValue(value?: string | null) {
  try {
    if (value) {
      return Boolean(JSON.parse(value))
    }
  } catch {
    return false
  }
}

export function getMapSettingFromLocalStorage() {
  const savedSettings = storage.getValue<PortfolioSavedSettings>(LOCAL_STORAGE_KEY, DEFAULT_SETTINGS)

  return { ...DEFAULT_SETTINGS, ...savedSettings }
}

export function saveMapSettingToLocalStorage(
  key: keyof PortfolioSavedSettings,
  value: PortfolioSavedSettings[typeof key]
) {
  storage.setValue(
    LOCAL_STORAGE_KEY,
    (prevState) => {
      const itemToStore = { ...prevState, [key]: value }
      // TODO: remove - temporary code to remove removed property from settings
      if ('customTickersOrder' in itemToStore) delete itemToStore.customTickersOrder
      return itemToStore
    },
    DEFAULT_SETTINGS
  )
}

const SUMMARY_DEFAULT: PortfolioSummary = {
  tickers: 0,
  transactions: 0,
  shares: 0,
  lastChangePct: 0,
  valuePaid: 0,
  valueMarket: 0,
  valueMarketStocks: 0,
  valueMarketStocksPrev: 0,
  gainMarketUsd: 0,
  gainMarketPct: 0,
  gainTodayUsd: 0,
}

export function getSummary(data: PortfolioGroup[]) {
  let allTransactions = 0
  let nonWatchTransactions = 0

  const computed = data.reduce((prev, current) => {
    allTransactions += current.transactions.length || 1
    nonWatchTransactions += current.transactions.length

    if (current.isDelisted) return prev

    const valueMarket = prev.valueMarket + current.valueMarket
    const valueMarketStocks =
      prev.valueMarketStocks + (current.type === PortfolioSymbolType.Cash ? 0 : current.valueMarket)
    const valueMarketStocksPrev =
      prev.valueMarketStocksPrev + (current.type === PortfolioSymbolType.Cash ? 0 : current.valueMarketPrev)
    const valuePaid = prev.valuePaid + current.valuePaid
    const lastChangePct = valueMarketStocksPrev <= 0 ? 0 : (valueMarketStocks / valueMarketStocksPrev - 1) * 100
    const gainMarketPct = valuePaid <= 0 ? 0 : (valueMarketStocks / valuePaid - 1) * 100

    return {
      ...prev,
      valueMarket: valueMarket,
      valueMarketStocks,
      valueMarketStocksPrev,
      lastChangePct,
      gainMarketPct,
      shares: prev.shares + current.shares,
      valuePaid: prev.valuePaid + current.valuePaid,
      gainMarketUsd: prev.gainMarketUsd + current.gainMarketUsd,
      gainTodayUsd: prev.gainTodayUsd + current.gainTodayUsd,
    }
  }, SUMMARY_DEFAULT)

  return {
    ...computed,
    transactions: nonWatchTransactions,
    allTransactions,
    tickers: data.length,
  }
}

function isMapTransaction(entry: PortfolioEntryTransaction): entry is PortfolioTransactionStake {
  return entry.shares > 0 || entry.type === PortfolioSymbolType.Cash
}

/**
 * Goes through all the items and merges them together so that we don’t end up
 * with multiple map tiles with same ticker & transaction
 */
export function flattenAndDedupe(data: PortfolioGroup[]) {
  const result: PortfolioMapEntry[] = []

  data.forEach((group) => {
    group.transactions.forEach((entry) => {
      if (!isMapTransaction(entry)) return

      const previouslyAdded = result.findIndex(
        (prev) =>
          prev.ticker === entry.ticker &&
          (entry.type === PortfolioSymbolType.Cash ? true : prev.transaction === entry.transaction)
      )

      if (previouslyAdded < 0) {
        const newEntry = { ...group, ...entry, transactions: undefined }
        delete newEntry.transactions
        result.push(newEntry)
      } else {
        const prevEntry = result[previouslyAdded]
        const valueMarket = prevEntry.valueMarket + entry.valueMarket
        const valuePaid = prevEntry.valuePaid + entry.valuePaid

        result[previouslyAdded] = {
          ...prevEntry,
          valuePaid,
          valueMarket,
          shares: prevEntry.shares + entry.shares,
          valueMarketPrev: prevEntry.valueMarketPrev + entry.valueMarketPrev,
          gainMarketUsd: prevEntry.gainMarketUsd + entry.gainMarketUsd,
          gainMarketPct: (valueMarket / valuePaid - 1) * 100,
          gainTodayUsd: prevEntry.gainTodayUsd + entry.gainTodayUsd,
        }
      }
    })
  })

  return Object.values(result)
}

/**
 * Checks if item is loser/gainer and returns the appropriate flag
 */
function getEntryGroup(type: PortfolioSymbolType, gain: number, transaction: PortfolioTransaction) {
  if (type === PortfolioSymbolType.Cash) return PortfolioDataKey.Cash

  switch (transaction) {
    case PortfolioTransaction.Short: {
      return gain > 0 ? PortfolioDataKey.ShortGainers : PortfolioDataKey.ShortLosers
    }

    default:
    case PortfolioTransaction.Buy: {
      return gain >= 0 ? PortfolioDataKey.LongGainers : PortfolioDataKey.LongLosers
    }
  }
}

/**
 * Creates industry node with children
 */
export function getIndustries(industries: Record<string, MapDataNode[]>) {
  return Object.keys(industries).map((industry) => ({
    name: industry,
    children: industries[industry],
  }))
}

/**
 * Group items by key
 * @see https://stackoverflow.com/a/47385953
 */
export function groupByKey<Item>(list: Item[], key: keyof Item) {
  return list.reduce<Record<string, Item[]>>((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, { [obj[key] as string]: (hash[obj[key] as string] || []).concat(obj) })
  }, {})
}

/**
 * Creates long/short gainers/losers group with all the children, optionally grouping them by industry
 */
function createGroup(key: PortfolioDataKey, data: PortfolioMapData, groupByIndustry = true) {
  let groupName = ''
  switch (key) {
    case PortfolioDataKey.Cash:
      groupName = 'Other'
      break
    default:
      const isLong = [PortfolioDataKey.LongGainers, PortfolioDataKey.LongLosers].includes(key)
      const isGainer = [PortfolioDataKey.LongGainers, PortfolioDataKey.ShortGainers].includes(key)
      const longPrefix = data.shortGainers.length || data.shortLosers.length ? `${groupPrefix.long}: ` : ''
      const shortPrefix = data.longGainers.length || data.longLosers.length ? `${groupPrefix.short}: ` : ''
      groupName = `${isLong ? longPrefix : shortPrefix}${isGainer ? groupLabel.gainers : groupLabel.losers}`
      break
  }

  if (groupByIndustry) {
    let children: Array<{ name: string; children: MapDataNode[] }> = []
    if (key === PortfolioDataKey.Cash && data[key].length) children = [{ name: 'Cash', children: data[key] }]
    else children = getIndustries(groupByKey(data[key], industryLabelKey))
    return { name: groupName, children }
  }

  return { name: groupName, children: data[key] }
}

export function getPerfText(node: PortfolioMapEntry, subtype: MapSubtypeId) {
  switch (subtype) {
    case MapSubtypeId.PortfolioGainPct:
      return node.gainMarketPct
    case MapSubtypeId.PortfolioGainUsd:
      return node.gainMarketUsd
    case MapSubtypeId.PortfolioChangePct:
      return node.lastChangePct
    case MapSubtypeId.PortfolioChangeUsd:
      return node.gainTodayUsd
  }
}

function getPerfForTicker<ValueType, ListType extends Record<string, ValueType> | any[]>(
  ticker: string,
  list?: ListType,
  group?: PortfolioDataKey
) {
  if (Array.isArray(list)) {
    return list.find((item) => item.name === ticker && item.data?.group === group)?.perf
  }

  return list?.[ticker]
}

/**
 * Returns data for the map. This data is deduped and split into gainers/losers & industries
 */
export function getMapData({
  data,
  perfData,
  size,
  subtype,
  groupByIndustry,
}: {
  data?: PortfolioGroup[]
  perfData?: PerfData
  size: PortfolioMapSizeKey
  subtype: MapSubtypeId
  groupByIndustry?: boolean
}) {
  if (!data) return { hasData: false }

  const dedupedData = flattenAndDedupe(data)

  const isPortfolioSubtype = subtype.startsWith('portfolio')
  const parsedData = dedupedData.reduce(
    (prev: PortfolioMapData, current) => {
      const group = getEntryGroup(current.type, current.gainMarketPct / 100, current.transaction)
      // The map can’t display negative values so we need to Math.abs
      let value = Math.abs(size === PortfolioMapSizeKey.MarketValue ? current.valueMarket : current.gainMarketUsd)
      if (!Number.isFinite(value)) value = 0

      const subtypePerf = getPerfText(current, subtype)
      const perfText = isPortfolioSubtype ? subtypePerf : getPerfForTicker(current.ticker, perfData?.nodes, group)

      const entry = {
        name: current.ticker,
        value,
        perf: current.type === PortfolioSymbolType.Cash ? null : (perfText ?? subtypePerf),
        additional:
          current.type === PortfolioSymbolType.Cash
            ? formatNumber(current.valueMarket)
            : getPerfForTicker(current.ticker, perfData?.additional, group),
        description: current.industry,
        data: { perfWeek: current.perfWeek, group },
      } as MapDataNode

      if (value > 0) {
        return { ...prev, [group]: [...prev[group], entry] }
      }

      return prev
    },
    {
      [PortfolioDataKey.Cash]: [],
      [PortfolioDataKey.LongGainers]: [],
      [PortfolioDataKey.LongLosers]: [],
      [PortfolioDataKey.ShortGainers]: [],
      [PortfolioDataKey.ShortLosers]: [],
    }
  )

  return {
    dataHash: new Date().getTime().toString(),
    hasData: Object.values(parsedData).some((arr) => arr.length > 0),
    data: {
      name: 'Portfolio',
      children: Object.keys(parsedData)
        .map((key) => createGroup(key as PortfolioDataKey, parsedData, groupByIndustry))
        .filter((category) => category.children.length),
    } as MapDataRoot,
  }
}

export function parsePortfolioQuery(params: URLSearchParams, defaultPortfolioId: number) {
  const currentPortfolio = Number.parseInt(params.get(PortfolioQuery.PortfolioId) ?? '')
  const portfolioId =
    Number.isFinite(currentPortfolio) && currentPortfolio !== 0 ? currentPortfolio : defaultPortfolioId

  return {
    portfolioId,
    params: Object.fromEntries(params.entries()) as Record<PortfolioQuery, string>, // Re-export the original query as key/val
    view: params.get(PortfolioQuery.View) ?? PortfolioView.View,
    tableView: (params.get(PortfolioQuery.TableView) as PortfolioTableView) ?? undefined,
    map: parseBoolFromValue(params.get(PortfolioQuery.Map)) ?? getMapSettingFromLocalStorage().showMap,
    size: (params.get(PortfolioQuery.Size) ?? PortfolioMapSizeKey.MarketValue) as PortfolioMapSizeKey,
    subtype: (params.get(PortfolioQuery.Subtype) ?? getMapSettingFromLocalStorage().subtype) as MapSubtypeId,
    group: params.get(PortfolioQuery.Group)?.toUpperCase().split(',') ?? [],
    tickers: params.get(PortfolioQuery.Tickers)?.split(',') ?? [],
  }
}

export function getColumnsByKeys(keys: ColumnId[]) {
  return keys.map((key) => columnMap[key])
}

export function getFormattedDate(format = PORTFOLIO_DATE_FORMAT_DATA) {
  return `${dateFns.format(new Date(), format)}`
}

export function validateEditRow(
  state: Partial<PortfolioFormEntry>,
  dateFormat = PORTFOLIO_DATE_FORMAT_DATA,
  checkFormat = true
) {
  const formErrors: FormErrors = {}

  if (state.ticker !== undefined && state.ticker.trim().length === 0) {
    formErrors.ticker = true
  }

  if (
    state.transaction !== PortfolioTransaction.Watch &&
    (!state.date || !dateFns.isValid(parseInputAsDate({ input: state.date, format: dateFormat, checkFormat })))
  ) {
    formErrors.date = true
  }

  if (
    state.transaction !== PortfolioTransaction.Watch &&
    (state.shares === undefined || isNaN(state.shares) || state.shares < 0)
  ) {
    formErrors.shares = true
  }

  if (
    state.transaction !== PortfolioTransaction.Watch &&
    (state.price === undefined || isNaN(state.price) || state.price <= 0)
  ) {
    formErrors.price = true
  }

  return { isValid: Object.keys(formErrors).length === 0, errors: formErrors }
}

export function parseInputAsDate({
  input,
  format = PORTFOLIO_DATE_FORMAT_VIEW,
  checkFormat = true,
}: {
  input: Date | string
  format?: string
  checkFormat?: boolean
}) {
  if (checkFormat && typeof input === 'string' && input.length !== format.length) {
    return new Date('Invalid Date')
  }
  if (input instanceof Date && dateFns.isValid(input)) return input

  return dateFns.parse(input.toString(), format, new Date())
}

export const FORM_ENTRY_KEYS = Object.keys(EMPTY_FORM_ENTRY)

function appendRowToFormData(serializer: URLSearchParams, row: Partial<PortfolioFormEntry>, index: number) {
  FORM_ENTRY_KEYS.forEach((key) => serializer.append(`${key}${index}`, `${row[key as keyof PortfolioEntryBase] ?? ''}`))
}

export function getBulkEditData(
  data: Array<PortfolioGroup | PortfolioFormGroup>,
  options: { resetCash?: boolean } = {}
): Record<string, PortfolioFormGroup> {
  return data.reduce(
    (acc, row) => {
      const id = getUuid()
      const transactions =
        row.transactions.length === 0 || (options.resetCash && getSymbolType(row.ticker) === PortfolioSymbolType.Cash)
          ? [getNewTransaction(row.ticker, PortfolioTransaction.Watch)]
          : row.transactions.map((transaction) => ({
              ...transaction,
              uuid: transaction.id ? `${transaction.id}` : getUuid(),
            }))
      return {
        ...acc,
        [id]: { id, ticker: row.ticker, transactions },
      }
    },
    {} as Record<string, PortfolioFormGroup>
  )
}

export function serializeFormData<
  EntryType extends { ticker: string; transactions?: Array<Pick<PortfolioFormEntry, keyof typeof EMPTY_FORM_ENTRY>> },
>(entries: EntryType[]) {
  const serializer = new URLSearchParams()
  entries
    .flatMap((entry) => {
      if (!entry.transactions || entry.transactions?.length === 0) {
        return getNewTransaction(entry.ticker, PortfolioTransaction.Watch)
      }

      return entry.transactions
    })
    .forEach((entry, index) => {
      if (entry.ticker.length) appendRowToFormData(serializer, entry, index)
    })

  return serializer
}

export async function validateFormData(data: PortfolioFormGroup[], validateFields = true) {
  if (data.length === 0) return { isValid: true, errors: {} }

  let isValid = true
  const errors: Record<string, Array<FormErrors | undefined>> = {}

  try {
    const tickers = data.map((row) => {
      const ticker = getSanitizedTicker(row.ticker.trim(), false, [PORTFOLIO_CASH_SYMBOL])
      return ticker.length ? ticker : api.QUOTE_INVALID_TICKER_REQUEST
    })
    const validatedTickers = await api.validateTickers(tickers)

    isValid = tickers.length === validatedTickers.length

    // Validate fields
    if (validateFields) {
      data.forEach((row, index) => {
        const validationResult = row.transactions.map((transaction) =>
          validateEditRow(transaction, PORTFOLIO_DATE_FORMAT_DATA, false)
        )

        const hasInvalidEntries = validationResult.some((result) => !result.isValid)
        const isInvalidTicker = validatedTickers[index] !== true

        if (hasInvalidEntries || isInvalidTicker) {
          isValid = false
        }

        const isRowValid = validationResult.every((row) => row.isValid)

        if (!isRowValid || isInvalidTicker) {
          errors[row.id] = validationResult.map((result, index) => {
            const errors = result.errors
            if (isInvalidTicker && index === 0) {
              return { ...errors, ticker: true }
            }

            return result.isValid ? undefined : errors
          })
        }
      })
    }
  } catch {
    return {
      isValid: false,
      errors: {},
    }
  }

  return { isValid, errors }
}

export function getNewTransaction(ticker: string, transaction = PortfolioTransaction.Buy): PortfolioFormEntry {
  const symbolType = getSymbolType(ticker)
  const allowedTransactions = getTransactionsForSymbolType(symbolType)

  return {
    ...EMPTY_FORM_ENTRY,
    uuid: getUuid() as string,
    ticker,
    type: getSymbolType(ticker),
    date: getFormattedDate(PORTFOLIO_DATE_FORMAT_DATA),
    transaction: [PortfolioTransaction.Watch, ...allowedTransactions].includes(transaction)
      ? transaction
      : allowedTransactions[0],
    shares: transaction === PortfolioTransaction.Watch || symbolType === PortfolioSymbolType.Cash ? 0 : undefined,
    price: transaction === PortfolioTransaction.Watch ? 0 : undefined,
  }
}

export function getPortfolioInitData() {
  let returnData = { isInitData: false, initData: api.EMPTY_RESPONSE }
  const initDataElement = document.getElementById('portfolio-init-data')
  if (initDataElement) {
    const initData = parseInitData<PortfolioInitData>('portfolio-init-data')
    initDataElement.remove()
    returnData = { isInitData: !!initData, initData: initData ?? api.EMPTY_RESPONSE }
  }
  return returnData
}

const EMPTY_TRANSACTION = { transaction: PortfolioTransaction.Watch, index: 0, price: 0 }

export function getTradesStructureData(portfolioData: PortfolioGroup[]) {
  return portfolioData
    .flatMap((ticker) => {
      const transactions = [...ticker.transactions]
      if (transactions.length === 0) {
        transactions.push(EMPTY_TRANSACTION as unknown as PortfolioEntryTransaction)
      }
      return transactions.map((transaction) => {
        const obj = { ...ticker } as Omit<PortfolioGroup, 'transactions'> & {
          transactions?: PortfolioEntryTransaction[]
        }
        delete obj.transactions
        return { ...obj, ...transaction, isLastTransaction: transactions.length === 1 }
      })
    })
    .sort((a, b) => a.id - b.id) as PortfolioTrade[]
}

export function getSymbolType(symbol?: string) {
  if (symbol?.toUpperCase() === PORTFOLIO_CASH_SYMBOL) return PortfolioSymbolType.Cash

  return PortfolioSymbolType.Stock
}

export function isStockTransaction(transaction?: PortfolioTransaction) {
  return transaction !== undefined && STOCK_TRANSACTIONS.includes(transaction)
}

export function isCashTransaction(transaction?: PortfolioTransaction) {
  return transaction !== undefined && CASH_TRANSACTIONS.includes(transaction)
}

export function getTransactionsForSymbolType(symbolType: PortfolioSymbolType) {
  const watchTransaction: PortfolioTransaction[] = []

  switch (symbolType) {
    case PortfolioSymbolType.Cash:
      return [...watchTransaction, ...CASH_TRANSACTIONS]
    default:
      return [...watchTransaction, ...STOCK_TRANSACTIONS]
  }
}

export function getPortfolioRefreshInterval() {
  return getMapsRefreshInterval({
    base: PORTFOLIO_REFRESH_INTERVAL_ELITE,
    free: PORTFOLIO_REFRESH_INTERVAL_FREE,
  })
}
