import React from 'react'

export function usePrevious<TValue>(value: TValue, setOnlyIfDefined: boolean = false) {
  const ref = React.useRef<TValue>()

  React.useEffect(() => {
    if (setOnlyIfDefined && (value === null || value === undefined)) return
    ref.current = value
  }, [setOnlyIfDefined, value])

  return ref.current
}
