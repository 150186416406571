import { type To } from 'react-router-dom'

import { decodeQueryString, encodeQueryString } from '../../../app/queryString'
import { SpecificChartFunctionality, TIMEFRAME } from '../../constants/common'
import { DateRangeType, Instrument } from '../../types'
import { CUSTOM_DATE_RANGE, CustomDateRangePrefixEnum, DATE_RANGE_PREFIX_DELMITER } from './custom-date-range-utils'

interface ITimeFrame {
  value: TIMEFRAME | 'intraday'
  label: string
  labelShort: string
  labelPageTitle?: string
}

export interface ITimeframeGroup {
  label: string
  timeframes: ITimeFrame[]
}

interface IDateRange {
  value: string
  label: string
  timeframe?: TIMEFRAME
}

export interface IRangeGroup {
  label?: string
  dateRanges: IDateRange[]
}

export const generateTimeframeGroups = (isPremium = true): ITimeframeGroup[] => {
  const premiumTimeframes: ITimeframeGroup[] = [
    {
      label: '',
      timeframes: [
        { value: TIMEFRAME.i1, label: '1 Minute', labelShort: '1M' },
        { value: TIMEFRAME.i2, label: '2 Minutes', labelShort: '2M' },
        { value: TIMEFRAME.i3, label: '3 Minutes', labelShort: '3M' },
        { value: TIMEFRAME.i5, label: '5 Minutes', labelShort: '5M' },
        { value: TIMEFRAME.i10, label: '10 Minutes', labelShort: '10M' },
        { value: TIMEFRAME.i15, label: '15 Minutes', labelShort: '15M' },
        { value: TIMEFRAME.i30, label: '30 Minutes', labelShort: '30M' },
      ],
    },
    {
      label: '',
      timeframes: [
        { value: TIMEFRAME.h, label: '1 Hour', labelShort: '1H', labelPageTitle: 'Hourly' },
        { value: TIMEFRAME.h2, label: '2 Hours', labelShort: '2H' },
        { value: TIMEFRAME.h4, label: '4 Hours', labelShort: '4H' },
      ],
    },
    {
      label: '',
      timeframes: [
        { value: TIMEFRAME.d, label: '1 Day', labelShort: 'D', labelPageTitle: 'Daily' },
        { value: TIMEFRAME.w, label: '1 Week', labelShort: 'W', labelPageTitle: 'Weekly' },
        { value: TIMEFRAME.m, label: '1 Month', labelShort: 'M', labelPageTitle: 'Monthly' },
      ],
    },
  ]

  const freeTimeframes = [
    {
      // intradayTimeframe is only for non premium users used as redirect to elite subscription
      label: '',
      timeframes: [{ value: 'intraday' as const, label: 'Intraday', labelShort: 'Intraday' }],
    },
    {
      label: '',
      timeframes: [
        { value: TIMEFRAME.d, label: '1 Day', labelShort: 'Daily' },
        { value: TIMEFRAME.w, label: '1 Week', labelShort: 'Weekly' },
        { value: TIMEFRAME.m, label: '1 Month', labelShort: 'Monthly' },
      ],
    },
  ]

  return isPremium ? premiumTimeframes : freeTimeframes
}

export function getTimeframeGroups(isPremium: boolean) {
  return generateTimeframeGroups(isPremium)
}

export const generateDateRangeGroups = ({
  isPremium,
  instrument,
}: {
  isPremium: boolean
  instrument: Instrument
}): IRangeGroup[] => {
  const isStock = instrument === Instrument.Stock
  const rangeGroups: Array<{ label?: string; dateRanges: IDateRange[] }> = [
    {
      label: 'Days',
      dateRanges: [
        { value: DateRangeType.d1, label: '1 Day', timeframe: isStock ? TIMEFRAME.i1 : TIMEFRAME.i5 },
        { value: DateRangeType.d5, label: '5 Days', timeframe: isStock ? TIMEFRAME.i5 : TIMEFRAME.i30 },
      ],
    },
    {
      label: 'Months',
      dateRanges: [
        { value: DateRangeType.m1, label: '1 Month', timeframe: TIMEFRAME.d },
        { value: DateRangeType.m3, label: '3 Months', timeframe: TIMEFRAME.d },
        { value: DateRangeType.m6, label: '6 Months', timeframe: TIMEFRAME.d },
      ],
    },
    {
      label: 'Years',
      dateRanges: [
        { value: DateRangeType.ytd, label: 'Year to Date', timeframe: TIMEFRAME.d },
        { value: DateRangeType.y1, label: '1 Year', timeframe: TIMEFRAME.d },
        { value: DateRangeType.y2, label: '2 Years', timeframe: TIMEFRAME.w },
        { value: DateRangeType.y5, label: '5 Years', timeframe: isPremium ? TIMEFRAME.w : TIMEFRAME.m },
        { value: DateRangeType.max, label: 'Max', timeframe: TIMEFRAME.m },
      ],
    },
  ]

  return rangeGroups
}

export function getDateRangeGroups({
  isPremium,
  customRange,
  isCustomAvailable = true,
  instrument,
}: {
  isPremium: boolean
  customRange?: string
  isCustomAvailable?: boolean
  instrument: Instrument
}) {
  const rangeGroups = generateDateRangeGroups({ isPremium, instrument })

  if (isPremium && isCustomAvailable !== false) {
    const [dateRangePrefix] = customRange?.split(DATE_RANGE_PREFIX_DELMITER) ?? []
    rangeGroups.push({
      dateRanges: [
        {
          value:
            customRange && Object.values<string>(CustomDateRangePrefixEnum).includes(dateRangePrefix)
              ? customRange
              : CUSTOM_DATE_RANGE,
          label: 'Custom',
        },
      ],
    })
  }

  return rangeGroups
}

export function getDateRangeGroupsWithHref({
  isPremium,
  customRange,
  generatePath,
  withReload,
  isCustomAvailable,
  instrument,
}: {
  isPremium: boolean
  isCustomAvailable?: boolean
  customRange?: string
  withReload?: boolean
  instrument: Instrument
  generatePath: (dateRange: IDateRange) => To
}) {
  return getDateRangeGroups({
    isPremium,
    isCustomAvailable,
    customRange,
    instrument,
  }).map((dateRangeGroup: IRangeGroup) => ({
    label: dateRangeGroup.label,
    dateRanges: dateRangeGroup.dateRanges.map((dateRange) => ({
      ...dateRange,
      href:
        !isPremium || Object.values(DateRangeType).includes(dateRange.value as DateRangeType)
          ? generatePath(dateRange)
          : undefined,
      withReload: withReload ?? !isPremium,
    })),
  }))
}

const FREE_PERIODS_STOCK = ['d', 'w', 'm']

export function generatePathForTimeframeDateRange({
  isPremium,
  pathname,
  timeframe,
  dateRange,
  utmCampaign,
  freeTimeframes = FREE_PERIODS_STOCK,
  dateRangeQueryKey = 'r',
}: {
  isPremium: boolean
  pathname: string
  timeframe: TIMEFRAME | 'intraday'
  dateRange?: string | null
  utmCampaign?: string
  freeTimeframes?: string[]
  dateRangeQueryKey?: string
}) {
  if (isPremium || freeTimeframes.includes(timeframe)) {
    return {
      pathname,
      search: `?${encodeQueryString({
        ...decodeQueryString(),
        i: undefined,
        p: timeframe,
        [dateRangeQueryKey]: dateRange ?? undefined,
      })}`,
    }
  }

  return {
    pathname: '/elite.ashx',
    search: `?${encodeQueryString({
      utm_source: 'finviz',
      utm_medium: 'banner',
      utm_campaign: utmCampaign || window.location.pathname,
    })}`,
  }
}

export const timeframeUtm: Partial<Record<SpecificChartFunctionality, string>> = {
  [SpecificChartFunctionality.quotePage]: 'quote-timeframe',
  [SpecificChartFunctionality.futuresPage]: 'futures-timeframe',
  [SpecificChartFunctionality.forexPage]: 'forex-timeframe',
  [SpecificChartFunctionality.cryptoPage]: 'crypto-timeframe',
}

export const daterangeUtm: Partial<Record<SpecificChartFunctionality, string>> = {
  [SpecificChartFunctionality.futuresPage]: 'futures-timeframe-daterange',
  [SpecificChartFunctionality.forexPage]: 'forex-timeframe-daterange',
  [SpecificChartFunctionality.cryptoPage]: 'crypto-timeframe-daterange',
}
