import * as dateFns from 'date-fns'

import { formatDateToStringUS } from '../../../app/shared/utils'
import { TIMEFRAME } from '../../constants/common'
import { Instrument } from '../../types'
import { generateDateRangeGroups } from './utils'

// **************START**************
// Functions from here until END have to be in sync with
// charts: app/utils/chart-date-range-utils.ts
// *********************************

export enum CustomDateRangePrefixEnum {
  Range = 'range',
  Prev = 'prev',
}

export const CUSTOM_DATE_RANGE = 'custom'
export const DATE_RANGE_PREFIX_DELMITER = '_'
const CUSTOM_DATE_RANGE_DELIMITER = 'x'
const CUSTOM_PREV_RANGE_DELIMITER = '-'

export function getParsedDateRange(dateRange?: string) {
  return dateRange?.split(DATE_RANGE_PREFIX_DELMITER) ?? []
}

export function parseCustomDateRangeUrlParam({ dateRange, anchorDate }: { dateRange: string; anchorDate: Date }) {
  const [dateRangePrefix, dateRangeValue] = getParsedDateRange(dateRange)

  if (dateRangePrefix === CustomDateRangePrefixEnum.Range) {
    const [dateFromString, dateToString] = dateRangeValue.replace(/-/g, '/').split(CUSTOM_DATE_RANGE_DELIMITER)
    const dateFrom = dateFromString ? new Date(dateFromString) : null
    const dateTo = dateToString ? new Date(dateToString) : null
    return {
      start: dateFrom && !Number.isNaN(dateFrom.getTime()) ? dateFrom : null,
      end: dateTo && !Number.isNaN(dateTo.getTime()) ? dateFns.endOfDay(dateTo) : null,
    }
  }

  if (dateRangePrefix === CustomDateRangePrefixEnum.Prev) {
    const [years, months, days] = dateRangeValue.split(CUSTOM_PREV_RANGE_DELIMITER).map(Number)

    if ([years, months, days].some(Number.isNaN)) {
      return { start: null, end: null }
    }

    return {
      start: dateFns.startOfDay(dateFns.sub(anchorDate, { years, months, days: days - 1 })),
      end: anchorDate,
    }
  }

  return { start: null, end: anchorDate }
}

export function getDefaultDateRangeAnchorDate() {
  // Uncomment for testing purposes
  // return dateFns.endOfDay(new Date('02-02-2023'))

  return new Date()
}

// **************END**************
// Functions below don't need to be
// in sync with Website repository
// *******************************

const DAYS_IN_YEAR = 365

export function getCustomStartEndDateRangeUrlParam({ start, end }: { start: Date | null; end: Date | null }) {
  const startString = start ? formatDateToStringUS(start) : ''
  const endString = end ? formatDateToStringUS(end) : ''

  const stringParts = [
    CustomDateRangePrefixEnum.Range,
    DATE_RANGE_PREFIX_DELMITER,
    startString,
    CUSTOM_DATE_RANGE_DELIMITER,
    endString,
  ]

  return stringParts.join('').replace(/\//g, '-')
}

export function getPrevPeriodDateRangeUrlParam({
  years,
  months,
  days,
}: {
  years: number
  months: number
  days: number
}) {
  const stringParts = [
    CustomDateRangePrefixEnum.Prev,
    DATE_RANGE_PREFIX_DELMITER,
    years,
    CUSTOM_PREV_RANGE_DELIMITER,
    months,
    CUSTOM_PREV_RANGE_DELIMITER,
    days,
  ]

  return stringParts.join('')
}

export function getTimeframeForDateRangeType({
  dateRange,
  isPremium = true,
  instrument,
}: {
  dateRange: string
  isPremium?: boolean
  instrument: Instrument
}) {
  const dateRanges = generateDateRangeGroups({ isPremium, instrument }).flatMap(({ dateRanges }) => dateRanges)
  return dateRanges.find(({ value }) => value === dateRange)?.timeframe ?? null
}

export function getTimeframeFromDateRange({
  dateRange,
  anchorDate,
  instrument,
  isPremium,
}: {
  dateRange: string
  anchorDate: Date
  instrument: Instrument
  isPremium: boolean
}) {
  const dateRangeTimeframe = getTimeframeForDateRangeType({ dateRange, instrument })
  if (dateRangeTimeframe) {
    return dateRangeTimeframe
  }
  const { start, end } = parseCustomDateRangeUrlParam({ dateRange, anchorDate })
  const startNyTime = start ?? new Date(0)
  const startToReferenceDateNumOfDays = dateFns.differenceInCalendarDays(anchorDate, startNyTime) + 1
  const rangeNumOfDays = dateFns.differenceInCalendarDays(end ?? anchorDate, startNyTime) + 1
  /**
   * DB_BARS_LIMIT - code below is dependent on amount of data we store for specific timeframes
   * Search DB_BARS_LIMIT in the project to find other places with such code.
   */
  if (rangeNumOfDays > 6 * DAYS_IN_YEAR) {
    return TIMEFRAME.m
  }

  if (rangeNumOfDays > 4 * DAYS_IN_YEAR) {
    return isPremium ? TIMEFRAME.w : TIMEFRAME.m
  }

  if (rangeNumOfDays > 1.5 * DAYS_IN_YEAR) {
    return TIMEFRAME.w
  }

  // Keep these values in sync with values in FetchQuoteIntraday in quote.cs
  if (instrument === Instrument.Stock) {
    if (rangeNumOfDays <= 1 && startToReferenceDateNumOfDays <= 11) {
      return TIMEFRAME.i1
    }

    if (rangeNumOfDays <= 5 && startToReferenceDateNumOfDays <= 16) {
      return TIMEFRAME.i5
    }

    if (rangeNumOfDays <= 7 && startToReferenceDateNumOfDays <= 200) {
      return TIMEFRAME.i30
    }
  } else {
    if (startToReferenceDateNumOfDays <= 2) {
      return TIMEFRAME.i5
    }
    if (startToReferenceDateNumOfDays <= 7) {
      return TIMEFRAME.i30
    }
  }

  return TIMEFRAME.d
}
