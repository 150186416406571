import * as dateFns from 'date-fns'
import * as React from 'react'

import { parseInputAsDate } from '../../modules/portfolio/utils'

interface Props {
  inputValue: string
  /**
   * Input format. If no format is specified, the input is treated as ISO
   */
  dataDateFormat?: string
  viewDateFormat: string
}

export function getDateInputWithPickerValue({ inputValue, dataDateFormat, viewDateFormat }: Props) {
  try {
    const parsedDate = parseInputAsDate({
      input: inputValue,
      format: dataDateFormat,
      checkFormat: false,
    })

    return {
      input: dateFns.format(parsedDate, viewDateFormat),
      picker: parsedDate,
    }
  } catch {
    return {
      input: inputValue,
      picker: new Date(),
    }
  }
}

export function useDateInputWithPickerValue({ inputValue, dataDateFormat, viewDateFormat }: Props) {
  return React.useMemo(
    () =>
      getDateInputWithPickerValue({
        inputValue,
        dataDateFormat,
        viewDateFormat,
      }),
    [inputValue, dataDateFormat, viewDateFormat]
  )
}
