import classNames from 'classnames'
import * as dateFns from 'date-fns'

import { RedGreenMultiline, RedGreenText } from '../../../components/RedGreenText'
import { TableAlign } from '../../../components/Table'
import { formatNumber } from '../../../util'
import { ColumnId, PortfolioSymbolType, PortfolioTransaction, RowKind, TableColumnDefinition } from '../types'
import { PORTFOLIO_DATE_FORMAT_VIEW, TRANSACTION_LABEL } from './general'

export const columnMap: Record<ColumnId, TableColumnDefinition> = Object.freeze({
  [ColumnId.Spacer]: {
    id: ColumnId.Spacer,
    urlSort: '',
    name: '',
    renderColumn: () => null,
  },
  [ColumnId.Ticker]: {
    id: ColumnId.Ticker,
    urlSort: 'ticker',
    name: 'Ticker',
    align: TableAlign.Left,
    renderColumn: (args) => {
      switch (args.kind) {
        case RowKind.Transaction:
        case RowKind.Summary:
          return null
        case RowKind.Group:
        case RowKind.Trade:
          if (args.row.type === PortfolioSymbolType.Stock && !args.row.isDelisted) {
            return (
              <a
                href={`/quote.ashx?t=${args.row.ticker}`}
                className="tab-link flex h-full w-full items-center"
                onClick={(ev) => ev.stopPropagation()}
              >
                {args.row.ticker}
              </a>
            )
          }

          return <span>{args.row.ticker}</span>
        default:
          return <span>{args.row.ticker}</span>
      }
    },
  },
  [ColumnId.Company]: {
    id: ColumnId.Company,
    urlSort: 'company',
    name: 'Company',
    align: TableAlign.Left,
    renderColumn: (args) => {
      switch (args.kind) {
        case RowKind.Transaction:
          return null
        case RowKind.Summary:
          return (
            <span className="whitespace-nowrap leading-none">
              Summary:{' '}
              <span className="sm:block xl:inline">
                {args.row.tickers} Ticker{args.row.tickers === 1 ? '' : 's'}
              </span>
              <span className="sm:hidden 2xl:inline"> / </span>
              <span className="sm:block 2xl:inline">
                {args.row.transactions} Transaction{args.row.transactions === 1 ? '' : 's'}
              </span>
            </span>
          )
        default:
          return <div className="line-clamp-2 max-w-96">{args.row.company}</div>
      }
    },
  },
  [ColumnId.CostAvg]: {
    id: ColumnId.CostAvg,
    urlSort: 'costavg',
    name: 'Avg. Cost',
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null

      switch (args.kind) {
        case RowKind.Transaction:
        case RowKind.Summary:
          return null
        case RowKind.Trade: {
          if (args.row.transaction === PortfolioTransaction.Watch) return null
          return formatNumber(args.row.costAvg)
        }
        default:
          return formatNumber(args.row.costAvg)
      }
    },
  },
  [ColumnId.LastClose]: {
    id: ColumnId.LastClose,
    urlSort: 'price',
    name: 'Price',
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null

      switch (args.kind) {
        case RowKind.Transaction:
        case RowKind.Summary:
          return null
        default:
          return formatNumber(args.row.lastClose)
      }
    },
  },
  [ColumnId.LastChange]: {
    id: ColumnId.LastChange,
    urlSort: 'lastchange',
    name: (
      <>
        <span className="xl:hidden">Chg.$</span>
        <span className="hidden xl:inline">Change $</span>
      </>
    ),
    align: TableAlign.Right,
    getValueForSort: (args) => (args.kind === RowKind.Group ? args.row.lastClose - args.row.prevClose : null),
    renderColumn: (args) => {
      switch (args.kind) {
        case RowKind.Transaction:
        case RowKind.Summary:
          return null
        default:
          if (args.row.isDelisted) return '-'

          const lastChange = args.row.lastClose - args.row.prevClose
          return (
            <RedGreenText value={lastChange} rightContent={null}>
              {formatNumber(lastChange, {
                showPlusSign: true,
                fractions: args.row.lastClose < 1 ? 4 : 2,
              })}
            </RedGreenText>
          )
      }
    },
  },
  [ColumnId.LastChangePct]: {
    id: ColumnId.LastChangePct,
    urlSort: 'changepct',
    name: (
      <>
        <span className="xl:hidden">Chg.%</span>
        <span className="hidden xl:inline">Change %</span>
      </>
    ),
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null

      switch (args.kind) {
        case RowKind.Transaction:
          return null
        default:
          return (
            <RedGreenText value={args.row.lastChangePct} rightContent={args.row.lastChangePct === null ? null : '%'}>
              {formatNumber(args.row.lastChangePct, { showPlusSign: true })}
            </RedGreenText>
          )
      }
    },
  },
  [ColumnId.LastVolume]: {
    id: ColumnId.LastVolume,
    urlSort: 'volume',
    name: 'Volume',
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null

      switch (args.kind) {
        case RowKind.Group:
        case RowKind.Trade:
          return formatNumber(args.row.lastVolume, { fractions: 0 })
        default:
          return null
      }
    },
  },
  [ColumnId.Transaction]: {
    id: ColumnId.Transaction,
    urlSort: 'transaction',
    name: 'Transaction',
    align: TableAlign.Left,
    renderColumn: (args) => {
      switch (args.kind) {
        case RowKind.Transaction:
        case RowKind.Trade:
          return TRANSACTION_LABEL[args.row.transaction]
        default:
          return null
      }
    },
  },
  [ColumnId.Date]: {
    id: ColumnId.Date,
    urlSort: 'date',
    name: 'Date',
    align: TableAlign.Left,
    renderColumn: (args) => {
      switch (args.kind) {
        case RowKind.Transaction:
        case RowKind.Trade:
          return (
            <div
              className={classNames('whitespace-nowrap', {
                'js-is-watch-date': args.row.transaction === PortfolioTransaction.Watch,
              })}
            >
              {dateFns.format(new Date(args.row.date), PORTFOLIO_DATE_FORMAT_VIEW)}
            </div>
          )
        default:
          return null
      }
    },
  },
  [ColumnId.Shares]: {
    id: ColumnId.Shares,
    urlSort: 'shares',
    name: 'Shares',
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null

      switch (args.kind) {
        case RowKind.Summary:
          return null
        case RowKind.Trade: {
          if (args.row.transaction === PortfolioTransaction.Watch) return null
          return formatNumber(args.row.shares, { fractions: 0 })
        }
        default:
          return formatNumber(args.row.shares, { fractions: 0 })
      }
    },
  },
  [ColumnId.Price]: {
    id: ColumnId.Price,
    urlSort: 'cost',
    name: (
      <>
        Cost<span className="hidden whitespace-pre xl:inline"> / Share</span>
      </>
    ),
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (
        (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) ||
        (args.kind === RowKind.Trade && args.row.transaction === PortfolioTransaction.Watch)
      )
        return null

      switch (args.kind) {
        case RowKind.Transaction:
        case RowKind.Trade:
          return formatNumber(args.row.price)
        default:
          return null
      }
    },
  },
  [ColumnId.ValuePaid]: {
    id: ColumnId.ValuePaid,
    urlSort: 'totalcost',
    name: 'Total Cost',
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null
      if (args.kind === RowKind.Trade && args.row.transaction === PortfolioTransaction.Watch) return null
      return formatNumber(args.row.valuePaid)
    },
  },
  [ColumnId.Amount]: {
    id: ColumnId.Amount,
    urlSort: 'amount',
    name: 'Amount',
    align: TableAlign.Right,
    getValueForSort: (args) => args.row.valuePaid,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Trade && args.kind !== RowKind.Transaction) return null
      return formatNumber(args.row.price)
    },
  },
  [ColumnId.ValueMarket]: {
    id: ColumnId.ValueMarket,
    urlSort: 'marketvalue',
    name: (
      <>
        <span className="xl:hidden">Mkt. Value</span>
        <span className="hidden xl:inline">Market Value</span>
      </>
    ),
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind === RowKind.Trade && args.row.transaction === PortfolioTransaction.Watch) return null
      return formatNumber(args.row.valueMarket)
    },
  },
  [ColumnId.GainMarketUsd]: {
    id: ColumnId.GainMarketUsd,
    urlSort: 'gainusd',
    name: (
      <>
        <span className="hidden whitespace-pre xl:inline">Total </span>Gain $
      </>
    ),
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null
      if (args.kind === RowKind.Trade && args.row.transaction === PortfolioTransaction.Watch) return null
      return (
        <RedGreenText value={args.row.gainMarketUsd} rightContent={null}>
          {formatNumber(args.row.gainMarketUsd)}
        </RedGreenText>
      )
    },
  },
  [ColumnId.GainMarketPct]: {
    id: ColumnId.GainMarketPct,
    urlSort: 'gainpct',
    name: (
      <>
        <span className="hidden whitespace-pre xl:inline">Total </span>Gain %
      </>
    ),
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null
      if (args.kind === RowKind.Trade && args.row.transaction === PortfolioTransaction.Watch) return null
      return (
        <RedGreenText value={args.row.gainMarketPct} rightContent={args.row.gainMarketPct === null ? null : '%'}>
          {formatNumber(args.row.gainMarketPct)}
        </RedGreenText>
      )
    },
  },
  [ColumnId.GainMarketCombined]: {
    id: ColumnId.GainMarketCombined,
    urlSort: 'gainmarket',
    name: 'Total Gain',
    align: TableAlign.Right,
    getValueForSort: (args) => args.row.gainMarketPct,
    renderColumn: (args) => (
      <RedGreenMultiline>
        <RedGreenText value={args.row.gainMarketUsd}>{formatNumber(args.row.gainMarketUsd)}</RedGreenText>
        <RedGreenText value={args.row.gainMarketPct} rightContent={args.row.gainMarketPct === null ? null : '%'}>
          {formatNumber(args.row.gainMarketPct)}
        </RedGreenText>
      </RedGreenMultiline>
    ),
  },
  [ColumnId.GainTodayUsd]: {
    id: ColumnId.GainTodayUsd,
    urlSort: 'changeusd',
    name: (
      <>
        <span className="xl:hidden">Chg.$</span>
        <span className="hidden xl:inline">Change $</span>
      </>
    ),
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.kind !== RowKind.Summary && args.row.type === PortfolioSymbolType.Cash) return null
      if (args.kind === RowKind.Trade && args.row.transaction === PortfolioTransaction.Watch) return null
      return (
        <RedGreenText value={args.row.gainTodayUsd} rightContent={null}>
          {formatNumber(args.row.gainTodayUsd)}
        </RedGreenText>
      )
    },
  },
  [ColumnId.GainTodayCombined]: {
    id: ColumnId.GainTodayCombined,
    urlSort: 'gaintoday',
    name: 'Today’s Gain',
    align: TableAlign.Right,
    getValueForSort: (args) => args.row.lastChangePct,
    renderColumn: (args) => (
      <RedGreenMultiline>
        <RedGreenText value={args.row.gainTodayUsd}>{formatNumber(args.row.gainTodayUsd)}</RedGreenText>
        <RedGreenText value={args.row.lastChangePct} rightContent="%">
          {formatNumber(args.row.lastChangePct)}
        </RedGreenText>
      </RedGreenMultiline>
    ),
  },
  [ColumnId.Order]: {
    id: ColumnId.Order,
    urlSort: 'order',
    name: 'No.',
    align: TableAlign.Right,
    renderColumn: (args) => {
      if (args.rowIndex === undefined || args.kind === RowKind.Summary) return null
      return args.rowIndex + 1 + '.'
    },
  },
})

export const columns = Object.values(columnMap)
